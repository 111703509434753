import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import '../CSS/AboutUs.css';

const AboutUs = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      offset: 200, // Offset from the original trigger
      easing: 'ease-in-out', // Easing animation
      once: true, // Whether animation should happen only once
      mirror: false, // Whether elements should animate out while scrolling past them
    });
  }, []);

  return (
    <div className="senior-care-container">
      <div
        className="senior-care-image"
        data-aos="fade-right"
      >
        {/* New Border Box */}
        <div className="border-box" data-aos="fade-in" data-aos-delay="300"></div>

        {/* Existing Image */}
        <img
          src="/static/banner/banner2.webp"
          alt="Nurse with Senior"
          data-aos="fade-up"
        />

        {/* Play Button */}
        <div
          className="play-button"
          data-aos="zoom-in"
          data-aos-delay="600"
        >
          <button>&#9654;</button>
        </div>
      </div>
      <div className="senior-care-content">
        <h2 data-aos="fade-down">Welcome to MedBridge Access</h2>
        <h1 data-aos="fade-up">Your Gateway to Premium Medical Tourism & Virtual Consultation</h1>
        <p data-aos="fade-up" data-aos-delay="200">
          Explore a world where health and travel meet. MedBridge Access is dedicated to providing top-tier medical tourism services alongside virtual consultations for second opinions, ensuring you have the best care possible wherever you are.
        </p>
        <div className="container">
          <div className="list-column" data-aos="fade-right" data-aos-delay="400">
            <ul>
              <li>Access to world-class healthcare facilities</li>
              <li>Personalized care plans</li>
              <li>Seamless travel and medical arrangements</li>
            </ul>
          </div>
          <div className="list-column" data-aos="fade-left" data-aos-delay="500">
            <ul>
              <li>Expert second opinions from renowned specialists</li>
              <li>Comprehensive follow-up care</li>
              <li>Ease of access with a state-of-the-art virtual environment integration</li>
            </ul>
          </div>
        </div>
        <div className="senior-care-features">
          <div className="feature" data-aos="flip-left" data-aos-delay="700">
            <span className="icon"><i className="fa-solid fa-piggy-bank"></i></span>
            <span><h4>Health Solutions</h4> Affordable medical tourism</span>
          </div>
          <div className="feature" data-aos="flip-right" data-aos-delay="800">
            <span className="icon"><i className="fa-solid fa-user-doctor"></i></span>
            <span><h4>Second Opinion </h4>Validate the diagnosis & treatment.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
