import React from 'react'
import '../CSS/DashboardContent.css'
import Calendar from './Calendar'
import DoctorStats from './DoctorStats';
function DashboardContent() {
    return (
        <div className="dashboard-doctor">
          
    
          <div className="dashboard-body">
            <div className="calendar-section">
            <div className="dashboard-banner">
            <div className="details-section">
            <DoctorStats 
  totalAppointments={0}
  pendingAppointments={0}
  canceledAppointments={0}
  previousTotal={0}  // This is the total from the previous period for comparison
/>
              {/* <AppointmentRequest /> */}
            </div>
          </div>
              <Calendar />
            </div>
    
            
          </div>
        </div>
      );
}

export default DashboardContent