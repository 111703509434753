import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendOtp, verifyOtp, resendOtp } from "../slices/doctorSlice";
import "../CSS/DoctorRegistrationForm.css";
import { Link, useNavigate } from "react-router-dom";
import TestimonialSection from "../Components/TestimonialSection";
import OtpModal from "../Components/OtpModal"; // Import the OtpModal component

const DoctorRegistrationForm = () => {

  const countriesArray = [
    'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua and Barbuda',
    'Argentina', 'Armenia', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas',
    'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin',
    'Bhutan', 'Bolivia', 'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'Brunei',
    'Bulgaria', 'Burkina Faso', 'Burundi', 'Cabo Verde', 'Cambodia', 'Cameroon',
    'Canada', 'Central African Republic', 'Chad', 'Chile', 'China', 'Colombia',
    'Comoros', 'Congo, Democratic Republic of the', 'Congo, Republic of the',
    'Costa Rica', 'Croatia', 'Cuba', 'Cyprus', 'Czech Republic', 'Denmark',
    'Djibouti', 'Dominica', 'Dominican Republic', 'Ecuador', 'Egypt', 'El Salvador',
    'Equatorial Guinea', 'Eritrea', 'Estonia', 'Eswatini', 'Ethiopia', 'Fiji',
    'Finland', 'France', 'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 'Greece',
    'Grenada', 'Guatemala', 'Guinea', 'Guinea-Bissau', 'Guyana', 'Haiti', 'Honduras',
    'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Israel',
    'Italy', 'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Kuwait',
    'Kyrgyzstan', 'Laos', 'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 'Libya',
    'Liechtenstein', 'Lithuania', 'Luxembourg', 'Madagascar', 'Malawi', 'Malaysia',
    'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Mauritania', 'Mauritius', 'Mexico',
    'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Morocco', 'Mozambique',
    'Myanmar', 'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'New Zealand', 'Nicaragua',
    'Niger', 'Nigeria', 'North Korea', 'North Macedonia', 'Norway', 'Oman', 'Pakistan',
    'Palau', 'Palestine', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines',
    'Poland', 'Portugal', 'Qatar', 'Romania', 'Russia', 'Rwanda', 'Saint Kitts and Nevis',
    'Saint Lucia', 'Saint Vincent and the Grenadines', 'Samoa', 'San Marino',
    'Sao Tome and Principe', 'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone',
    'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia', 'South Africa', 'South Korea',
    'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Sweden', 'Switzerland', 'Syria',
    'Taiwan', 'Tajikistan', 'Tanzania', 'Thailand', 'Timor-Leste', 'Togo', 'Tonga', 'Trinidad and Tobago',
    'Tunisia', 'Turkey', 'Turkmenistan', 'Tuvalu', 'Uganda', 'Ukraine', 'United Arab Emirates',
    'United Kingdom', 'United States', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Vatican City', 'Venezuela',
    'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe'
  ];

  const degreesArray = [
    "MBBS",                     // Bachelor of Medicine and Bachelor of Surgery
    "MBBS, MD",                 // MBBS with Doctor of Medicine (Postgraduate)
    "MBBS, MS",                 // MBBS with Master of Surgery (Postgraduate)
    "MBBS, MD, DM",             // MBBS with MD and Doctorate of Medicine (Super-specialty)
    "MBBS, MS, MCh",            // MBBS with MS and Master of Chirurgiae (Super-specialty)
    "MBBS, MD, DNB",            // MBBS with MD and Diplomate of National Board (Alternative to MD/MS)
    "MBBS, DNB",                // MBBS with Diplomate of National Board (Postgraduate)
    "MBBS, MD, PhD",            // MBBS with MD and Doctor of Philosophy (Research-focused)
    "MBBS, MS, PhD",            // MBBS with MS and Doctor of Philosophy (Research-focused)
    "MBBS, MD, FRCS",           // MBBS with MD and Fellowship of the Royal College of Surgeons
    "MBBS, MS, FRCS",           // MBBS with MS and FRCS (Surgical specialization)
    "MBBS, MD, FCCP",           // MBBS with MD and Fellow of the American College of Chest Physicians
    "MBBS, MD, FACC",           // MBBS with MD and Fellow of the American College of Cardiology
    "MBBS, MS, FAANS",          // MBBS with MS and Fellow of the American Association of Neurological Surgeons
    "MBBS, MD, FACP",           // MBBS with MD and Fellow of the American College of Physicians
    "MBBS, MD, MRCP",           // MBBS with MD and Membership of the Royal Colleges of Physicians (UK)
    "MBBS, MS, MRCSEd",         // MBBS with MS and Membership of the Royal College of Surgeons of Edinburgh
    "MBBS, MD, FRCP",           // MBBS with MD and Fellowship of the Royal College of Physicians
    "MBBS, MS, FRCOG",          // MBBS with MS and Fellowship of the Royal College of Obstetricians and Gynaecologists
    "MBBS, MD, DM, MCh",        // MBBS with MD, DM, and MCh (Highest qualification in super-specialty fields)
    "MBBS, MD, PGDCC",          // MBBS with MD and Postgraduate Diploma in Clinical Cardiology
    "MBBS, MD, PGDCA",          // MBBS with MD and Postgraduate Diploma in Anaesthesia
    "MBBS, MS, DGO",            // MBBS with MS and Diploma in Gynaecology and Obstetrics
    "MBBS, MS, DO",             // MBBS with MS and Diploma in Ophthalmology
    "MBBS, MD, DMRD",           // MBBS with MD and Diploma in Medical Radiodiagnosis
    "MBBS, MD, DCH",            // MBBS with MD and Diploma in Child Health
    "MBBS, MD, DDVL",           // MBBS with MD and Diploma in Dermatology, Venereology, and Leprosy
    "MBBS, MD, DPM",            // MBBS with MD and Diploma in Psychological Medicine (Psychiatry)
    "MBBS, MD, DNB, DM",        // MBBS with MD, DNB, and DM (Comprehensive specialization path)
    "MBBS, MD, DNB, MCh",       // MBBS with MD, DNB, and MCh (Comprehensive surgical path)
    "MBBS, MD, DM, FACC",       // MBBS with MD, DM, and Fellow of the American College of Cardiology
    "MBBS, MD, DM, FACS",       // MBBS with MD, DM, and Fellow of the American College of Surgeons
    "MBBS, MD, MHA",            // MBBS with MD and Master of Hospital Administration
    "MBBS, MD, PGDM",           // MBBS with MD and Postgraduate Diploma in Medicine (Various specializations)
    "MBBS, MD, DMRE",           // MBBS with MD and Diploma in Medical Radiodiagnosis & Imaging
    "MBBS, MS, FAIS",           // MBBS with MS and Fellowship of the Association of Indian Surgeons
    "MBBS, MS, FIAGES",         // MBBS with MS and Fellowship of Indian Association of Gastrointestinal Endo Surgeons
    "MBBS, MD, FIDSA",          // MBBS with MD and Fellow of the Infectious Diseases Society of America
    "MBBS, MD, FICP",           // MBBS with MD and Fellow of the Indian College of Physicians
    "MBBS, MD, FRCPath",        // MBBS with MD and Fellowship of the Royal College of Pathologists
    "MBBS, MD, FESC",           // MBBS with MD and Fellowship of the European Society of Cardiology
    "MBBS, MD, FICOG",          // MBBS with MD and Fellow of the Indian College of Obstetricians and Gynecologists
    "MBBS, MD, DM, FACG",       // MBBS with MD, DM, and Fellow of the American College of Gastroenterology
    "MBBS, MS, FMAS",           // MBBS with MS and Fellowship in Minimal Access Surgery
    "MBBS, MD, FASN",           // MBBS with MD and Fellow of the American Society of Nephrology
    "MBBS, MD, FAPA",           // MBBS with MD and Fellow of the American Psychiatric Association
    "MBBS, MD, DNB, PDCC",      // MBBS with MD, DNB, and Post-Doctoral Certificate Course in Critical Care
    "MBBS, MS, DM, FICS"        // MBBS with MS, DM, and Fellow of the International College of Surgeons
  ];


  const branchesArray = [
    "Orthopedic",                // Specializes in the musculoskeletal system
    "Oncologist",                // Specializes in cancer treatment
    "Cardiologist",              // Specializes in heart and cardiovascular system
    "Neurologist",               // Specializes in the nervous system
    "Pediatrician",              // Specializes in child healthcare
    "General Physician",         // Broad field of internal medicine
    "Pulmonologist",             // Specializes in the respiratory system
    "Gastroenterologist",        // Specializes in the digestive system
    "Endocrinologist",           // Specializes in the endocrine system (hormones)
    "Nephrologist",              // Specializes in kidney health
    "Hepatologist",              // Specializes in liver health
    "Dermatologist",             // Specializes in skin, hair, and nail disorders
    "Rheumatologist",            // Specializes in joints, muscles, and autoimmune diseases
    "Hematologist",              // Specializes in blood disorders
    "Radiologist",               // Specializes in medical imaging (CT, MRI, X-rays)
    "Interventional Radiologist",// Specializes in minimally invasive image-guided procedures
    "Psychiatrist",              // Specializes in mental health and disorders
    "Psychologist",              // Focuses on behavioral therapy and mental health
    "Ophthalmologist",           // Specializes in eye health and surgery
    "Otolaryngologist",          // Specializes in ear, nose, and throat (ENT)
    "Urologist",                 // Specializes in urinary tract and male reproductive organs
    "Gynecologist",              // Specializes in female reproductive health
    "Obstetrician",              // Specializes in pregnancy and childbirth
    "Anesthesiologist",          // Specializes in anesthesia and perioperative medicine
    "Emergency Medicine",        // Specializes in emergency and trauma care
    "Critical Care Specialist",  // Specializes in intensive care for critically ill patients
    "Immunologist",              // Specializes in the immune system and allergies
    "Allergist",                 // Specializes in allergies and immunologic diseases
    "Pathologist",               // Specializes in diagnosing diseases through lab tests
    "Forensic Pathologist",      // Specializes in determining cause of death (forensics)
    "Vascular Surgeon",          // Specializes in the treatment of blood vessels
    "Plastic Surgeon",           // Specializes in reconstructive and cosmetic surgery
    "Cardiothoracic Surgeon",    // Specializes in surgery of the heart and chest
    "Neurosurgeon",              // Specializes in brain and spinal cord surgery
    "Pediatric Surgeon",         // Specializes in surgical care for children
    "Orthopedic Surgeon",        // Specializes in bone, joint, and ligament surgery
    "Bariatric Surgeon",         // Specializes in weight-loss surgeries
    "Colorectal Surgeon",        // Specializes in colon and rectal surgery
    "Transplant Surgeon",        // Specializes in organ transplants
    "Hand Surgeon",              // Specializes in surgery of the hand and wrist
    "Spine Surgeon",             // Specializes in surgery of the spine
    "Maxillofacial Surgeon",     // Specializes in surgery of the face and jaws
    "Trauma Surgeon",            // Specializes in emergency surgery for trauma cases
    "Sports Medicine Specialist",// Specializes in treating sports-related injuries
    "Geriatrician",              // Specializes in elderly healthcare
    "Perinatologist",            // Specializes in maternal-fetal medicine (high-risk pregnancies)
    "Neonatologist",             // Specializes in care of newborn infants
    "Occupational Medicine",     // Focuses on workplace health and safety
    "Reproductive Endocrinologist", // Specializes in fertility and reproductive health
    "Pain Medicine Specialist",  // Specializes in managing chronic pain
    "Palliative Care Specialist",// Focuses on relieving suffering in terminally ill patients
    "Nuclear Medicine Specialist",// Specializes in diagnosing and treating diseases with radioactive substances
    "Sleep Medicine Specialist", // Specializes in sleep disorders and their treatment
    "Clinical Pharmacologist",   // Specializes in the effects and uses of drugs in humans
    "Geneticist",                // Specializes in genetics and hereditary disorders
    "Medical Oncologist",        // Specializes in chemotherapy and cancer treatment
    "Radiation Oncologist",      // Specializes in cancer treatment using radiation
    "Infectious Disease Specialist", // Specializes in treating infections
    "Preventive Medicine Specialist",// Focuses on prevention of disease and promotion of health
    "Toxicologist",              // Specializes in the effects of toxins and poisons
    "Public Health Specialist",  // Focuses on community health and disease prevention
    "Hyperbaric Medicine Specialist", // Specializes in hyperbaric oxygen therapy for treating injuries or conditions like decompression sickness
    "Medical Geneticist",        // Specializes in diagnosing and treating genetic disorders
    "Clinical Immunologist",     // Specializes in autoimmune and immunodeficiency disorders
    "Dermatosurgeon",            // Specializes in skin surgery
    "Pediatric Neurologist",     // Specializes in neurological issues in children
    "Gastrointestinal Surgeon",  // Specializes in surgery of the digestive tract
    "Hepatobiliary Surgeon",     // Specializes in liver, gallbladder, and bile duct surgeries
    "Oculoplastic Surgeon",      // Specializes in plastic surgery around the eyes
    "Thoracic Surgeon",          // Specializes in surgery of the chest organs excluding the heart
    "Endovascular Specialist",   // Specializes in minimally invasive treatment of vascular diseases
    "Phlebologist",              // Specializes in the treatment of venous disorders (e.g., varicose veins)
    "Medical Toxicologist",      // Specializes in diagnosing and treating effects of poisons
    "Clinical Pathologist",      // Specializes in laboratory analysis of bodily fluids
    "Molecular Biologist",       // Specializes in molecular mechanisms of diseases
    "Osteopath",                 // Specializes in osteopathic manipulative medicine
    "Cosmetic Surgeon",          // Specializes in aesthetic procedures to improve appearance
    "Rehabilitation Specialist", // Focuses on recovery from illness or injury
    "Speech-Language Pathologist",// Specializes in speech and communication disorders
    "Audiologist"                // Specializes in hearing and balance disorders
  ];


  const [step, setStep] = useState(1); // Step 1: Profile Info, Step 2: Other Details
  const [formData, setFormData] = useState({
    user: {
      username: "",
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    },
    country: "",
    phone: "",
    degree: "",
    specialization: "",
    years_of_experience: "",
    branch: "",
    profile_photo: null,
  });
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [step1Errors, setStep1Errors] = useState({}); // Track errors in Step 1
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    status,
    otpStatus,
    otpError,
    resendOtpStatus,
    resendOtpError,
    error,
    doctorData,
  } = useSelector((state) => state.doctor);
  
  // Scroll to top when an error occurs
  useEffect(() => {
    if (error) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [error]);
  
  useEffect(() => {
    // If doctor is already logged in, redirect to doctor dashboard
    if (doctorData) {
      navigate("/doctors/doctordashboard");
    }
  }, [doctorData, navigate]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "profile_photo") {
      const file = e.target.files[0]; // Get the selected file
      if (file) {
        setFormData({
          ...formData,
          profile_photo: file,
          profile_photo_preview: URL.createObjectURL(file),
        });
      } else {
        setFormData({
          ...formData,
          profile_photo: null,
          profile_photo_preview: null,
        });
      }
    } else if (name.startsWith("user.")) {
      const userKey = name.split(".")[1];
      setFormData((prevFormData) => ({
        ...prevFormData,
        user: {
          ...prevFormData.user,
          [userKey]: value,
          ...(userKey === "email" ? { username: value } : {}),
        },
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  
  // Validate Step 1 Fields
  const validateStep1 = () => {
    const errors = {};
  
    if (!formData.user.first_name) errors.first_name = "First Name is required";
    if (!formData.user.last_name) errors.last_name = "Last Name is required";
    if (!formData.user.email) errors.email = "Email is required";
    if (!formData.user.password) errors.password = "Password is required";
    if (!formData.profile_photo) errors.profile_photo = "Profile photo is required";
  
    setStep1Errors(errors);
  
    // Return true if there are no errors
    return Object.keys(errors).length === 0;
  };
  
  const handleNextStep = () => {
    // Validate Step 1 before moving to Step 2
    if (validateStep1()) {
      setStep(2);
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top if there are errors
    }
  };
  
  const handleResendOtp = async () => {
    const email = formData.user.email;
    try {
      const resultAction = await dispatch(resendOtp(email));
      if (resendOtp.fulfilled.match(resultAction)) {
        console.log("OTP resent successfully");
      }
    } catch (err) {
      console.error("Failed to resend OTP:", err);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
  
    // Serialize the user data to JSON and append it
    data.append("user", JSON.stringify(formData.user));
  
    // Append other form data, but exclude profile_photo_preview
    Object.keys(formData).forEach((key) => {
      if (key !== "user" && key !== "profile_photo_preview") {
        data.append(key, formData[key]);
      }
    });
  
    try {
      const resultAction = await dispatch(sendOtp(data));
      if (sendOtp.fulfilled.match(resultAction)) {
        // OTP sent successfully, open the OTP modal
        setIsOtpModalOpen(true);
      }
    } catch (err) {
      console.error("Failed to send OTP:", err);
    }
  };
  
  const handleOtpSubmit = async () => {
    const email = formData.user.email;
    try {
      const resultAction = await dispatch(verifyOtp({ email, otp }));
      if (verifyOtp.fulfilled.match(resultAction)) {
        setIsOtpModalOpen(false);
        navigate("/doctors/doctordashboard");
      }
    } catch (err) {
      console.error("Failed to verify OTP:", err);
    }
  };
  
  const renderErrorMessages = (errorResponse) => {
    if (!errorResponse) return null;
  
    const errors = errorResponse.errors;
    if (!errors || errors.length === 0) return null;
  
    // Show only the first error
    const firstError = errors[0];
    return (
      <div className="error-message">
        {getFriendlyErrorMessage(firstError.code)}
      </div>
    );
  };
  
  const getFriendlyErrorMessage = (errorCode) => {
    const errorMessages = {
      INVALID_JSON: "There was a problem with your submission.",
      USER_DATA_REQUIRED: "Please fill in all required fields.",
      EMAIL_REQUIRED: "We need your email address.",
      USER_ALREADY_EXISTS: "An account with this email already exists.",
      EMAIL_SEND_FAILED: "Failed to send email. Please try again later.",
      OTP_SESSION_NOT_FOUND: "No OTP session data found. Please request a new OTP.",
      OTP_EXPIRED: "Your OTP has expired. Please request a new one.",
      INVALID_OTP: "The OTP you entered is incorrect.",
      INVALID_USER_TYPE: "Invalid user type. Please try again.",
      USER_NOT_FOUND: "User does not exist.",
      NETWORK_ERROR: "Network error occurred. Please check your connection.",
      FIRST_NAME_ERROR: "Please provide your first name.",
      LAST_NAME_ERROR: "Don't forget your last name!",
      PASSWORD_ERROR: "A secure password is required.",
      COUNTRY_ERROR: "Where are you from?",
      PHONE_ERROR: "Your phone number, please.",
      DEGREE_ERROR: "What's your qualification?",
      SPECIALIZATION_ERROR: "We need to know your specialization.",
      YEARS_OF_EXPERIENCE_ERROR: "Experience matters! Tell us.",
      BRANCH_ERROR: "Which branch are you from?",
      PROFILE_PHOTO_ERROR: "A photo would be great!",
      // Default message
      default: "Something went wrong, try again later.",
    };
  
    return errorMessages[errorCode] || errorMessages.default;
  };
  
  return (
    <div className="registration-container">
      <form className="form-section" onSubmit={handleSubmit}>
        <div className="logo-placeholder">
          <div className="logo-box">
            <img
              src="/static/icons/logo.webp"
              alt="Medbridge-access-logo"
              className="Medbridge-access-logo"
            />
            <div className="logo">
              <h1>
                <span className="title-we">Med</span>
                <div className="title-2">
                  <span>Bridge</span> <span>Access</span>
                </div>
              </h1>
            </div>
          </div>
        </div>
        <div className="registration-form">
          <h3>Create Account</h3>
          <p className="form-subheading">
            Join us and start making a difference in the world.
          </p>
  
          {/* Handle and display error messages */}
          {error && <p className="error-class">{renderErrorMessages(error)}</p>}
  
          {step === 1 ? (
            <>
              {/* Step 1: Profile Information */}
              <div className="profile-photo-container">
                <label className="profile-photo-placeholder">
                  {formData.profile_photo_preview ? (
                    <img
                      src={formData.profile_photo_preview}
                      alt="Profile"
                      className="profile-photo-preview"
                    />
                  ) : (
                    <img
                      src="/static/icons/medical-assistance.webp"
                      alt="Default profile icon"
                    />
                  )}
                  <input
                    type="file"
                    name="profile_photo"
                    onChange={handleChange}
                    className="profile-photo-input"
                    required
                  />
                </label>
                {step1Errors.profile_photo && <span className="error-text">{step1Errors.profile_photo}</span>}
              </div>
  
              <input
                type="text"
                name="user.first_name"
                id="first_name"
                placeholder="First Name"
                onChange={handleChange}
                className="form-input"
                autocomplete="given-name"
                required
              />
              {step1Errors.first_name && <span className="error-text">{step1Errors.first_name}</span>}
  
              <input
                type="text"
                name="user.last_name"
                id="last_name"
                placeholder="Last Name"
                onChange={handleChange}
                className="form-input"
                autocomplete="family-name"
                required
              />
              {step1Errors.last_name && <span className="error-text">{step1Errors.last_name}</span>}
  
              <input
                type="email"
                name="user.email"
                id="email"
                placeholder="Email"
                autocomplete="email"
                onChange={handleChange}
                className="form-input"
                required
              />
              {step1Errors.email && <span className="error-text">{step1Errors.email}</span>}
  
              <input
                type="password"
                name="user.password"
                placeholder="Password"
                onChange={handleChange}
                className="form-input"
                required
              />
              {step1Errors.password && <span className="error-text">{step1Errors.password}</span>}
  
              <button
                type="button"
                onClick={handleNextStep} // Validate and move to next step
                className="submit-button"
              >
                Continue
              </button>
              <p className="form-end">
                Already have an account?
                <Link to="/doctors/login">Log In</Link>
              </p>
            </>
          ) : null}
  
          {step === 2 ? (
            <>
              {/* Step 2: Other Details */}
              <select
                name="country"
                value={formData.country}
                onChange={handleChange}
                className="form-input"
                required
              >
                <option value="" disabled hidden>Select Country</option>
                {countriesArray.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </select>
              <input
                type="text"
                name="phone"
                autocomplete="off"
                placeholder="Phone"
                onChange={handleChange}
                className="form-input"
                required
              />
  
              <select
                name="degree"
                value={formData.degree}
                onChange={handleChange}
                className="form-input"
                required
              >
                <option value="" disabled hidden>Select Degree</option>
                {degreesArray.map((degree) => (
                  <option key={degree} value={degree}>
                    {degree}
                  </option>
                ))}
              </select>
  
              <input
                type="text"
                name="specialization"
                autocomplete="off" // Disable autofill if necessary
                placeholder="Specialization"
                onChange={handleChange}
                className="form-input"
              />
  
              <input
                type="number"
                name="years_of_experience"
                placeholder="Years of Experience"
                onChange={handleChange}
                className="form-input"
              />
  
              <select
                name="branch"
                value={formData.branch}
                onChange={handleChange}
                className="form-input"
                required
              >
                <option value="" disabled hidden>Select Branch</option>
                {branchesArray.map((branch) => (
                  <option key={branch} value={branch}>
                    {branch}
                  </option>
                ))}
              </select>
  
              <div className="form-end-box">
                <button
                  type="submit"
                  disabled={status === "loading"}
                  className="submit-button"
                >
                  {status === "loading" ? "Submitting..." : "Create Account"}
                </button>
  
                <p className="form-end">
                  Already have an account?
                  <Link to="/doctors/login">Log In</Link>
                </p>
              </div>
            </>
          ) : null}
        </div>
      </form>
      <OtpModal
        isOpen={isOtpModalOpen}
        onClose={() => setIsOtpModalOpen(false)}
        otp={otp}
        setOtp={setOtp}
        onSubmit={handleOtpSubmit}
        onResend={handleResendOtp}
        error={otpError}
        loading={otpStatus === "loading"}
        resendOtpStatus={resendOtpStatus}
        resendOtpError={resendOtpError}
      />
      <TestimonialSection />
    </div>
  );
}  

export default DoctorRegistrationForm;
