// MedicalTourismOptions.jsx
import React, { useEffect } from 'react';
import '../CSS/MedicalTourismOptions.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const MedicalTourismOptions = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true,     // Whether animation should happen only once
    });
  }, []);

  return (
    <div className="medical-tourism-container">
      <div className="medical-tourism-header">
        <h2>Discover Your Global Healthcare Options</h2>
      </div>
      <div className="medical-tourism-content">
        <div className="medical-tourism-image-section">
          <img src="/static/banner/banner4.webp" alt="Person with arms raised" />
        </div>
        <div className="medical-tourism-info-section">
          <div
            className="medical-tourism-info-box"
            data-aos="fade-right"
          >
            <p>
              Need a second opinion? MedBridge Access connects you with leading medical experts worldwide, empowering you to make confident and informed healthcare decisions.
            </p>
            <button onClick={() => window.location.href = '/bookappointment'}>
              Get a Second Opinion
            </button>
          </div>
          <div
            className="medical-tourism-info-box"
            data-aos="fade-right"
            data-aos-delay="200"
          >
            <p>
              Dreaming of medical tourism? Explore our in-depth destination guides that blend top-tier healthcare with unforgettable travel experiences. Find the best hospitals, renowned specialists, and comfortable accommodations tailored to your needs.
            </p>
            <button onClick={() => window.location.href = '/destination-guides'}>
              Explore Destinations
            </button>
          </div>
          <div
            className="medical-tourism-info-box"
            data-aos="fade-right"
            data-aos-delay="400"
          >
            <p>
              Searching for specific treatments? MedBridge Access offers a comprehensive selection of over 1,000 treatments worldwide, ensuring you find the perfect procedure that suits your health requirements.
            </p>
            <button onClick={() => window.location.href = '/services'}>
              Discover Treatments
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicalTourismOptions;
