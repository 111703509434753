// src/components/AnimatedImage.jsx

import React, { useEffect, useRef, useState } from 'react';
import '../CSS/About.css';

const AnimatedImage = ({ src, alt, animation }) => {
  const imgRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1, // Trigger when 10% of the image is visible
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Stop observing once visible
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    // Cleanup on unmount
    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  return (
    <div className="image">
      <img
        ref={imgRef}
        src={src}
        alt={alt}
        className={isVisible ? `animate-${animation}` : ''}
      />
    </div>
  );
};

export default AnimatedImage;
