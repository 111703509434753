import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../CSS/MedicalProcess.css'; // Updated CSS file name

const MedicalProcess = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  const steps = [
    {
      title: 'Explore',
      description: 'Find and research top hospitals & destinations',
      icon: <img src="/static/icons/search.webp" alt="Explore" className="medical-icon-img" />,
      animation: 'fade-up',
    },
    {
      title: 'Quote',
      description: 'Request a quote directly through our website',
      icon: <img src="/static/icons/medical-invoice.webp" alt="Quote" className="medical-icon-img" />,
      animation: 'fade-up',
    },
    {
      title: 'Match',
      description: 'Based on your criteria, we recommend the best hospitals or providers',
      icon: <img src="/static/icons/balanced.webp" alt="Match" className="medical-icon-img" />,
      animation: 'fade-up',
    },
    {
      title: 'Arrange',
      description: 'The hospital/provider will contact you directly to coordinate your treatment',
      icon: <img src="/static/icons/networking.webp" alt="Arrange" className="medical-icon-img" />,
      animation: 'fade-up',
    },
    {
      title: 'Treat',
      description: 'Finalize your treatment with the hospital/provider of your choice',
      icon: <img src="/static/icons/first-aid-kit.webp" alt="Treat" className="medical-icon-img" />,
      animation: 'fade-up',
    },
  ];

  return (
    <div className="medical-process">
      <h2 className="process-heading">The Simplest Process in Finding Healthcare Abroad</h2>
      <p className="process-subheading">We'll Help You Map Out Your Medical Tourism Journey</p>
      <div className="medical-steps">
        {steps.map((step, index) => (
          <div
            key={index}
            className="medical-step"
            data-aos={step.animation}
            data-aos-delay={index * 200} // Stagger the animations by 200ms
          >
            <div className="medical-icon">{step.icon}</div>
            <h3 className="medical-title">{step.title}</h3>
            <p className="medical-description">{step.description}</p>
          </div>
        ))}
      </div>
      <button className="medical-quote-button">Request a Quote for Healthcare Now</button>
    </div>
  );
};

export default MedicalProcess;
