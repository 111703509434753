import React from 'react';
import { FileText, Users, Star } from 'lucide-react';
import { useSelector } from 'react-redux';

const DoctorSidebar2 = () => {
  // Access doctorData from Redux state
  const doctorData = useSelector((state) => state.doctor.doctorData);
  const doctor = doctorData?.doctor;

  // Fallbacks in case of missing data
  const doctorName =
    (doctor?.user?.first_name || '') + ' ' + (doctor?.user?.last_name || '') || 'Doctor Name';
  const profilePhoto = doctor?.profile_photo || '/static/icons/profile.png';
  const specialization = doctor?.specialization || 'Specialization';
  const degree = doctor?.degree || 'Degree Not Available';
  const yearsOfExperience = doctor?.years_of_experience || 'N/A';
  const totalPatients = 0; // Replace with actual data if available
  const rating = 0; // Replace with actual data if available
  const messages = []; // Replace with actual data if available
  console.log(doctor.years_of_experience  )
  return (
    <div className="doctorSidebar2">
      <div className="doctorSidebar2__content">
        <div className='doctorSidebar2__profile-box'>
        <div className="doctorSidebar2__profile-section">
        
          <div className="doctorSidebar2__doctor-info">
            <h2>Dr. {doctorName}</h2>
            <span>
              <p>{degree}</p>
              <p>, {specialization}</p>
            </span>
          </div>
          <div className="doctorSidebar2__avatar">
            <img src={profilePhoto} alt="Doctor" />
          </div>
        </div>

        {/* Total Patients Section */}
        <div className="doctorSidebar2__patients-section">
          <div className="doctorSidebar2__patients-stats">
            <div className="doctorSidebar2__stat-item">
              
              <div className="doctorSidebar2-box">
                <span className="doctorSidebar2__stat-label">Patients</span>
                <span className="doctorSidebar2__stat-value">{totalPatients}</span>
              </div>
            </div>
          </div>

          <div className="doctorSidebar2__ratings-stats">
            <div className="doctorSidebar2__stat-item">
             
              <div className="doctorSidebar2-box">
                <span className="doctorSidebar2__stat-label">Experience</span>
                <span className="doctorSidebar2__stat-value doctorSidebar2__stat-value-2 ">{doctor.years_of_experience}yrs+</span>
              </div>
            </div>
          </div>
        </div>
        </div>
        {/* Document Section */}
        <div className="doctorSidebar2__document-section">
  <h3>About Me</h3>
  <div className="doctorSidebar2__document-list">
    <p>
      Hello, I'm Dr. {doctor.user.first_name} {doctor.user.last_name}, and I have the honor of serving as an {doctor.branch} specialist with over {doctor.years_of_experience} years of dedicated experience. With a foundation in {doctor.degree}, I’m committed to providing exceptional care and support to each of my patients.
      {doctor.specialization && ` My passion lies in ${doctor.specialization}, where I strive to help individuals live healthier, more active lives.`}
     Currently practicing in {doctor.country}, I look forward to making a positive difference in your healthcare journey.</p>
  </div>
</div>


        {/* Message Section */}
        <div className="doctorSidebar2__message-section">
          <span
            className="doctorSidebar2__message_heading"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <h3>Message</h3>
            <span style={{ margin: '0 6px' }}>•</span>
            <p className="doctorSidebar2__unread-count">No unread messages</p>
          </span>

          <div className="doctorSidebar2__message-list">
            {messages.map((name, index) => (
              <div key={index} className="doctorSidebar2__message-item">
                <div className="doctorSidebar2__message-avatar">
                  <span className="doctorSidebar2__avatar-initial">{name.charAt(0)}</span>
                </div>
                <div className="doctorSidebar2__message-content">
                  <p className="doctorSidebar2__sender-name">{name}</p>
                  <p className="doctorSidebar2__message-preview">
                    Bagaimana cara keren untuk s...
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorSidebar2;
