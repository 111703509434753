import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPatientDetails } from '../slices/patientSlice';
import { fetchDoctorAppointmentsByDate } from '../slices/doctorSlice';
import { useNavigate } from 'react-router-dom';
import '../CSS/Timeline.css';


const TimelineItem = ({ appointment, onPatientClick, isUpcoming }) => {
  const appointmentTime = new Date(appointment.appointment_time);
  // Extract time without AM/PM
  const timeHours = appointmentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
  // Extract AM/PM only
  const timePeriod = appointmentTime.getHours() >= 12 ? 'PM' : 'AM';

  return (
    <div className={`timeline__item ${isUpcoming ? 'timeline__item--upcoming' : ''} `}   onClick={() => appointment.patient && onPatientClick(appointment.patient.id)}>
      <div className="timeline__time" >
        <div className="timeline__time-period">{timePeriod}</div>
        <div className="timeline__time-value">{timeHours}</div>
      </div>
      <div className="timeline__content">
        <div className="timeline__appointment-box">
          <div className="timeline__details">
            <div className="timeline__patient-name">
              {appointment.patient?.user?.first_name || 'N/A'}{' '}
              {appointment.patient?.user?.last_name || ''}
            </div>
            <div className="timeline__heading">Heart Surgeon</div>
          </div>
          <div className="timeline__icons">
            <img src="/static/icons/call.webp" alt="Call" className="timeline__icon" />
            <img src="/static/icons/video.webp" alt="Video Call" className="timeline__icon" />
          </div>
        </div>
      </div>
    </div>
  );
};



const Timeline = ({ selectedDate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appointments = useSelector((state) => state.doctor.appointments);
  const status = useSelector((state) => state.doctor.status);
  const error = useSelector((state) => state.doctor.error);

  useEffect(() => {
    dispatch(fetchDoctorAppointmentsByDate(selectedDate));
  }, [selectedDate, dispatch]);

  const handlePatientClick = (patientId) => {
    dispatch(fetchPatientDetails(patientId)).then(() => {
      navigate(`/doctors/chat/${patientId}`);
    });
  };

  if (status === 'loading') {
    return <p className="timeline__loading">Loading appointments...</p>;
  }

  if (error) {
    const errorMessage =
      typeof error === 'string'
        ? error
        : error.detail || 'An error occurred while fetching appointments';
    return (
      <p className="timeline__error">Error fetching appointments: {errorMessage}</p>
    );
  }

  const now = new Date();
  const upcomingAppointment = appointments.reduce((closest, appointment) => {
    const appointmentTime = new Date(appointment.appointment_time);
    if (appointmentTime > now && (!closest || appointmentTime < new Date(closest.appointment_time))) {
      return appointment;
    }
    return closest;
  }, null);

  return (
    <div className="timeline-container">
      <h4 className="timeline__header">Your Appointments</h4>
      <div className="timeline">
        {appointments.length === 0 ? (
          <p className="timeline__no-appointments">
            No appointments scheduled for this date.
          </p>
        ) : (
          appointments.map((appointment, index) => (
            <TimelineItem
              key={index}
              appointment={appointment}
              onPatientClick={handlePatientClick}
              isUpcoming={upcomingAppointment?.appointment_time === appointment.appointment_time} // Highlight upcoming appointment
            />
          ))
        )}
      </div>
    </div>
  );
};



export default Timeline;
