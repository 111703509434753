import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import PatientProfile from '../Components/PatientProfile';
import ChatWindow from '../Components/ChatWindow';
import { fetchPatientDetails, clearPatientDetails } from '../slices/patientSlice';

const ChatAppContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const DoctorChatApp = () => {
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { details: patient, loading, error } = useSelector((state) => state.patient);
  const doctorData = useSelector((state) => state.doctor.doctorData);

  useEffect(() => {
    if (patientId) {
      dispatch(fetchPatientDetails(patientId));
    }

    return () => {
      dispatch(clearPatientDetails());
    };
  }, [dispatch, patientId]);

  const generateRoomName = (user1Id, user2Id) => {
    return [user1Id, user2Id].sort().join('_');
  };

  if (loading) return <p>Loading patient details...</p>;
  if (error) return <p>Error loading patient details: {error.message}</p>;

  if (!patient || !patient.user || !doctorData || !doctorData.doctor || !doctorData.doctor.user) {
    return <p>Loading data...</p>;
  }

  // Flatten the doctor data to match the expected structure
  const currentUser = {
    ...doctorData.doctor,
    user_type: 'doctor',
    access_token: doctorData.access_token,
    refresh_token: doctorData.refresh_token,
  };

  // Ensure the patient has a user_type
  const patientWithUserType = { ...patient, user_type: 'patient' };

  return (
    <ChatAppContainer>
      <PatientProfile selectedUser={patient} />
      <ChatWindow 
        selectedUser={patientWithUserType} 
        roomName={generateRoomName(doctorData.doctor.user.id, patient.user.id)} 
        currentUser={currentUser} 
      />
    </ChatAppContainer>
  );
};

export default DoctorChatApp;
