// src/Banner.js
import React, { useEffect } from 'react';
import '../CSS/Banner.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Banner = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true,     // Whether animation should happen only once while scrolling down
      // You can add more AOS settings here if needed
    });
  }, []);

  return (
    <div className="slider-container">
      <div className="boxes-container">
        <div className="box box1" data-aos="fade-up">
          <div className="icon-container">
            <img src="/static/icons/doctor.webp" alt="Our Services" />
          </div>
          <div className="text-container">
            <h4>Our Services</h4>
            <h2>Medical Opinions & Tourism</h2>
          </div>
        </div>
        
        <div className="box box2" data-aos="fade-up" data-aos-delay="200">
          <div className="icon-container">
            <img src="/static/icons/hospital.webp" alt="Our Facilities" />
          </div>
          <div className="text-container">
            <h4>Our Facilities</h4>
            <h2>World-Class Hospitals & Specialists Network</h2>
          </div>
        </div>
        
        <div className="box box3" data-aos="fade-up" data-aos-delay="400">
          <div className="icon-container">
            <img src="/static/icons/sethoscope.webp" alt="Our Tools" />
          </div>
          <div className="text-container">
            <h4>Our Tools</h4>
            <h2>Digital Platform & Expert Team.</h2>
          </div>
        </div>
      </div>
      
      <div className="slide" >
        <img src="/static/banner/banner1.webp" alt="Banner Slide" className="slide-image" />
      </div>
    </div>
  );
}

export default Banner;
