import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../CSS/DoctorDashboard.css';
import DoctorSidebar from '../Components/DoctorSidebar';
import DashboardContent from '../Components/DashboardContent';
import DoctorSidebar2 from '../Components/DoctorSidebar2';
import MobileHeader from '../Components/MobileHeader';

const DoctorDashboard = () => {
  const doctorData = useSelector((state) => state.doctor.doctorData);
  const doctor = doctorData?.doctor;
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  console.log('Doctor Data:', doctorData);
  console.log('Doctor:', doctor);

  useEffect(() => {
    if (!doctorData) {
      navigate('/doctors/login');
    }
  }, [doctorData, navigate]);

  if (!doctorData) {
    return null; // or a loading spinner
  }

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className='doctor-dashboard'>
      <div className='doctor-dashboard-overlay'>
      <MobileHeader toggleSidebar={toggleSidebar} />
      <div className='dashboard-box'>
        <DoctorSidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
        <div
          className={`sidebar-overlay ${isSidebarOpen ? 'active' : ''}`}
          onClick={() => setIsSidebarOpen(false)}
        ></div>
        <DashboardContent />
        <DoctorSidebar2 />
      </div>
      </div>
    </div>
  );
};

export default DoctorDashboard;
