import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UserSidebar from '../Components/UserSidebar';
import MobileHeader from '../Components/MobileHeader';
import { Navigate } from 'react-router-dom';
import '../CSS/UserDashboard.css';
import AppointmentModal from '../Components/AppointmentModal';
import QuoteModal from '../Components/QuoteModal'; // Import QuoteModal
import { FaPlus, FaEllipsisV } from 'react-icons/fa';
import { fetchAppointments } from '../slices/userSlice';

const Dashboard = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user.userData);
  const appointments = useSelector((state) => state.user.appointments);
  const [isModalOpen, setIsModalOpen] = useState(false); // For appointment modal
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false); // For quote modal
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    if (userState && userState.patient) {
      dispatch(fetchAppointments());
    }
  }, [dispatch, userState]);

  // Check if userState is defined and contains patient data
  if (!userState || !userState.patient) {
    return <Navigate to="/bookappointment" />;
  }

  const {
    user: { username = '', email = '', first_name = '', last_name = '' } = {},
    country = '',
    dob = '',
    gender = '',
    phone = '',
    medical_histories = [],
    medical_reports = [],
  } = userState.patient;

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Function to format date to "14 Sept 2024"
  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  // Function to map categories to user-friendly names
  const mapCategoryName = (category) => {
    const categoryMap = {
      registration: 'Registration',
      appointment: 'Appointment',
      diagnosis: 'Diagnosis'
    };
    return categoryMap[category] || category;
  };

  return (
    <div className="dashboard">
      <div className='dashboard-overlay'>
      <MobileHeader toggleSidebar={toggleSidebar} />
      <div className='dashboard-box'>
        <UserSidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
        <div 
          className={`sidebar-overlay ${isSidebarOpen ? 'active' : ''}`} 
          onClick={() => setIsSidebarOpen(false)}
        ></div>

        <div className='dashboard-section'>
          <div className='dashboard-left'>
            <h1 className="username">Welcome, {first_name ? first_name : username}</h1>

            <div className='appointment-cards'>
              {appointments && appointments.length > 0 ? (
                appointments.map((appointment) => (
                  <div key={appointment.id} className="appointment-card">
                    <div className="doctor-info">
                      <img
                        src={appointment.doctor.profile_photo}
                        alt={`Dr. ${appointment.doctor.user.first_name} ${appointment.doctor.user.last_name}`}
                        className="doctor-image"
                      />
                      <div className="doctor-details">
                        <h2>{`Dr. ${appointment.doctor.user.first_name} ${appointment.doctor.user.last_name}`}</h2>
                        <p className="speciality">{`${appointment.doctor.degree}, ${appointment.doctor.country}`}</p>
                      </div>
                    </div>
                    <div className="doctor-info-2">
                      <p className="branch">{appointment.doctor.branch}</p>
                      <p className="specialization">{appointment.doctor.specialization}</p>
                    </div>
                    <div className="datetime-info">
                      <div className="datetime-pair">
                        <span className="datetime-key">Date</span>
                        <span className="datetime-value">{new Date(appointment.appointment_time).toLocaleDateString()}</span>
                      </div>
                      <div className="datetime-pair">
                        <span className="datetime-key">Time</span>
                        <span className="datetime-value">{new Date(appointment.appointment_time).toLocaleTimeString()}</span>
                      </div>
                    </div>

                    <div className="appointment-actions">
                      <span>{appointment.status}</span>
                      <div className='appointment-actions-buttons'>
                        <button>
                          <img src="/static/icons/call.webp" alt="Call" />
                        </button>
                        <button>
                          <img src="/static/icons/video.webp" alt="Video Call" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="appointment-card">
                   <h4 className='not-found-message'>No appointments found.</h4>
                </div>
              )}
            </div>
          </div>

          <div className='dashboard-right'>
            <h2>Make An Appointment, That Will Help You!</h2>
            <div className="actions">
              <button className="free-quote-button" onClick={() => setIsQuoteModalOpen(true)}>
                Free Quote
              </button>
              <button className='book-appointment-button' onClick={() => setIsModalOpen(true)}>
                Book Appointment
              </button>
            </div>

            <div className="medical-history">
              <div className="history-header">
                <h2>Medical History</h2>
                <div className="history-actions">
                  <a href="#" className="see-all">See All</a>
                </div>
              </div>
              <div className="history-content">
                {medical_histories.length > 0 ? (
                  medical_histories.map((item, index) => (
                    <div className="history-item" key={index}>
                      <p className="history-date">{formatDate(item.created_at)}</p>
                      <h4 className="history-title">{mapCategoryName(item.category)}</h4>
                      <p className="history-description">
                        {item.description.length > 30 ? `${item.description.slice(0, 20)}...` : item.description}
                      </p>
                    </div>
                  ))
                ) : (
                  <h4 className='not-found-message'> No medical history found.</h4>
                )}
              </div>
            </div>

            <div className="medical-reports">
              <div className="reports-header">
                <h2>Medical Reports</h2>
                <FaEllipsisV className="menu-icon" />
              </div>
              <div className="reports-content">
                {medical_reports.length > 0 ? (
                  medical_reports.map((report, index) => (
                    <a key={index} href={report.file} target="_blank" rel="noopener noreferrer" className="report-item">
                      <div className="report-details">
                        <p className="report-date">{formatDate(report.uploaded_at)}</p>
                        <img src="/static/icons/file.webp" alt="File Icon" className="report-icon" />
                        <p className="report-name">
                          {report.file.split('/').pop().split('.')[0].slice(0, 20)}...
                        </p>
                      </div>
                    </a>
                  ))
                ) : (
                  <h4 className='not-found-message'>No medical reports found.</h4>
                )}
              </div>
            </div>

          </div>

          {/* Quote Modal */}
          <QuoteModal
            isOpen={isQuoteModalOpen}
            onClose={() => setIsQuoteModalOpen(false)}
          />

          {/* Appointment Modal */}
          <AppointmentModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        </div>
      </div>
      </div>
    </div>
  );
};

export default Dashboard;
